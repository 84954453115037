.page-content {
	.tablepress-table-description {
		color: $primary;
		font-size: 2.4em;
		margin-bottom: 0.7em;
		@extend .font-stack-medium;
	}

	.tablepress {
		tfoot, thead {
			th { background: $primary !important;
				color: #fff;
				font-size: 1.5em;
				padding: 12px 20px;
				word-wrap: break-word;
			}
		}
	}

	.tablepress {
		tbody {
			&.odd {
				td { background-color: $offwhite; border-top: 0; }
			}
			td { border-top: 0;
					font-size: 1.4em;
					@media ( min-width: 992px ) {
						font-size: 1.5em;
					}
				padding: 12px 20px;
				word-wrap: break-word;
			}
		}
	}
}