.interactive-map {
    padding: 0 0 45px;
    text-align: center;
  
    @media(max-width: 767px) {
      padding: 80px 20px;
    }
  
    .image-points {
      position: relative;
  
      img {
        height: auto;
        max-width: 100%;
      }
  
    }
  
    .points {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  
    .points .plus {
      position: absolute;
      height: 45px;
      width: 45px;
      opacity: 0.6;
      transition: opacity 0.2s;

      &:hover {
          opacity: 1;
      }
  
      img {
        height: auto;
        width: 100%;
        border-radius: 50%;
        box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.3);
      }
  
    }
  
    .points .plus8 {
      left: 65%;
      top: 68%;
    }
  
  }

.fancybox-slide { }

.fancybox-lightbox { padding: 0 20px; margin: 0; background: transparent; overflow: visible;
  @media ( min-width: 1151px ) {
    max-width: 1108px;
  }
  .fancybox-close-small { top: -35px; right: -10px;
    @media ( min-width: 1151px ) {
      top: -42px; right: 0;
    }
    &:hover, &:active, &:focus {
      &:after { outline: none; border: none; }
    }
    &:after { background: transparent; color: $white; font-size: 40px;
      &:hover, &:active, &:focus { outline: none; border: none; }
    }
  }
  .fancybox-image-container { line-height: 0; position: relative; margin: 0 auto;
    @media ( min-width: 1151px ) {
      max-width: 1000px;
    }
    img { max-width: 100%; height: auto; transform: translateZ(0);}
      .fancybox-arrow { height: 100%; opacity: 1; display: flex; align-items: stretch; align-content: center; margin: 0; top: 0; background: rgba(30, 30, 30, .8);
        @media ( max-width: 767px ) {
          width: 24px;
        }
        &:after { position: relative; top: auto; background-color: transparent; margin: 0 auto;
          @media ( max-width: 767px ) {
            background-size: 14px 24px;
          }
        }
      @media ( min-width: 1151px ) {
        &.fancybox-arrow--left { left: -54px; }
        &.fancybox-arrow--right { right: -54px; }
      }
    }
  }
  .lightbox-content { margin: 0 auto; padding: 20px 0;
    &:not(.signle-slide) {
      @media (min-width: 1151px) {
        padding: 20px 34px
      }
    }
    p { color: $white; font-size: 1.5em; }
    .lightbox-header { font-size: 1.8em; @extend .font-stack-medium; }
    .content { margin-bottom: 5px;}
    .image-count { font-size: 1.2em; margin-bottom: 0; color: $lightgray; }
  }
}