body {
	overflow-x: visible!important;
  @extend .font-stack-regular;
  color: $darkgray;
  font-size: 10px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.grey-bg {
  //background: $offwhite;
}

.gradient-bg {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5e5e5+0,ffffff+15,ffffff+100 */
  //background: #e5e5e5; /* Old browsers */
  //background: -moz-linear-gradient(top,  #e5e5e5 0%, $white 15%, $white 100%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(top,  #e5e5e5 0%, $white 15%,$white 100%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(to bottom,  #e5e5e5 0%, $white 15%,$white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}