#tribe-events-pg-template, .tribe-events-pg-template {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

/* Tribe Events Bar
======================================*/
#tribe-events-bar {
  background: $white;
	padding: 0 20px;
	@media ( min-width: 993px ) {
		padding: 40px 20px;
	}
  margin-bottom: 0;
}
#tribe-bar-form {
  background: transparent;
  margin: 0 auto;
  max-width: 1135px;
  label {
    @extend .font-stack-medium;
    display: block;
    font-size: 1.4em;
    @media ( min-width: 1151px ) {
      font-size: 1.8em;
    }
    text-transform: none;
    margin-bottom: 20px;
  }
  .tribe-bar-filters-inner {

    .tribe-bar-date-filter,
    .tribe-bar-search-filter,
    .tribe-bar-submit { width: 100%;
      @media (min-width: 768px ) {
        width: 33.3333%;
      }
      @media ( min-width: 768px ) {
        margin-left: 0;
      }
    }
    input[type=text] { border: 1px solid transparent; background: $offwhite; font-style: normal; color: $darkgray; padding: 12px; width: 80%;
      &::placeholder { text-transform: uppercase; font-style: normal; color: $darkgray; font-size: 13px; @extend .font-stack-medium; }
      &:active, &:hover, &:focus { border: 1px solid transparent; outline: none; }
    }
  }


  #tribe-bar-views {
    label { padding: 0 15px; font-size: 1.4em;
      @media ( min-width: 1151px ) {
        padding: 0;
      }
      i { margin-left: 5px; color: $darkgray;}
    }
    span {
      background-size: 30px 30px; height: 30px; line-height: 30px; text-indent: 15px; margin-bottom: 10px; text-transform: uppercase;
      &.tribe-icon-month { background-image: url(/wp-content/themes/cantigny/assets/img/icon-month.png); }
      &.tribe-icon-list { background-image: url(/wp-content/themes/cantigny/assets/img/icon-list.png); }
    }
  }
  .tribe-bar-views-list {  padding: 5px 15px;
    @media ( min-width: 1151px ) {
      padding: 0 0 0 5px;
    }
    .tribe-bar-views-option {
      &.tribe-bar-active {
        a { background: transparent;
          &:hover { background: transparent;}
        }
      }
      a { background: transparent; padding: 0;
        &:hover { background: transparent;}
      }
    }
  }

}

.tribe-bar-views-inner {
  background: $white;
}

.tribe-bar-submit {
  // float: none;
  // width: 100%;
  // clear: both;
  margin-left: 0;
  width: 15%;
}

#tribe-bar-form .tribe-bar-submit input[type=submit] {
  display: inline-block;
  width: auto;
  background: $accent;
  border: 1px solid transparent;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background 0.2s, color 0.2s, border-color 0.2s;
  transition: background 0.2s, color 0.2s, border-color 0.2s;
  margin: 0;
  line-height: 1;
  padding: 15px 20px;
  &:hover {
    background: $accent_dark;
  }
}


/* Tribe Events Content
======================================*/
#tribe-events-content {
  &.tribe-events-list { @extend .inner; }
  @extend .inner;
}

#tribe-geo-results h2.tribe-events-page-title, h2.tribe-events-page-title,
.tribe-events-list h2.tribe-events-page-title {
  background: $primary;
  color: $white;
  font-size: 2.2em;
  padding: 30px 0;
  margin-bottom: 0;
}

#tribe-events-footer, #tribe-events-header {
  display: none;
}

#tribe-events-content table.tribe-events-calendar {
  margin-top: 0;
}

.tribe-events-calendar thead th {
  background-color: $offwhite;
  border-color: $offwhite;
  color:$gray;
}

#tribe-events-content .tribe-events-calendar td.tribe-events-othermonth,
#tribe-events-content .tribe-events-calendar td,
#tribe-events-content .tribe-events-calendar td:hover {
  background: $white;
  box-shadow: none;
  border-color: $lightgray;
}

.tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-]>a,
.tribe-events-calendar div[id*=tribe-events-daynum-], .tribe-events-calendar div[id*=tribe-events-daynum-] a {
  background: $white;
  color: $midgray;
  text-align: right;
}

#tribe-events .tribe-events-button, #tribe-events .tribe-events-button:hover, #tribe_events_filters_wrapper input[type=submit], .tribe-events-button, .tribe-events-button.tribe-active:hover, .tribe-events-button.tribe-inactive, .tribe-events-button:hover, .tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-]>a {
  background: $white;
  color: $primary;
  text-align: right;
}
@media (max-width: 767px){
	.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-] { text-align: center; }
	#tribe-events-header .tribe-events-sub-nav li h2 { font-size: 2.2em; padding: 20px 0; }
}

.tribe-events-present {
  border: 2px solid $primary!important;
}

h2.tribe-events-page-title a,
h2.tribe-events-page-title a:focus, h2.tribe-events-page-title a:hover {
  color: $white;
}

#tribe-events-header {
  display: block;
  margin-bottom: 0;
}

#tribe-events-header .tribe-events-sub-nav .tribe-events-nav-next a, #tribe-events-header .tribe-events-sub-nav li {
  color: $white;
  display: block;
  background: transparent;
}

#tribe-events-content a {

  @extend .font-stack-medium;
  color:$gray;
  font-size: 1.3em;
}

ul.tribe-events-sub-nav a {
  color: $white!important;
  padding: 0 20px;
  background: transparent;
}

#tribe-events-footer {
  display: block;
  margin-bottom: 50px;

  a {
    color: $primary!important;
  }

}

.events-list .tribe-events-loop, .single-tribe_organizer .tribe-events-loop, .single-tribe_venue .tribe-events-loop, .tribe-events-day .tribe-events-loop {
  max-width: 100%;
  width: 100%;
  }


  .tribe-events-ical {
    display: none!important;
  }

/* Tribe Events Header
======================================*/
#tribe-events-header { background: $primary; padding: 25px 0; color: $white; font-size: 1em;
  .tribe-events-sub-nav { display: flex; flex-flow: row nowrap; align-items: center;
    li{ flex-grow: 1; font-size: 1em;
      h2 { font-size: 2.2em; } @extend .font-stack-medium;
      &:not(.event-nav-item) { flex-grow: 2; text-align: center; }
    }
    a {
      .fa-stack { width: 2.5em; height: 2.5em; line-height: 2.5em; }
      .fa-stack-2x { font-size: 2.5em; }
      .fa-stack-1x { font-size: 1.25em; line-height: 2em; }
      .fa-circle { color: darken($primary, 10% );}
      //.fa-circle { color: $accent;}
      &:hover {
        .fa-circle { color: $primary_dark}
        //.fa-circle { color: $accent_dark; }
      }
    }
    .month-name { display: none; }
  }
}

/* Tribe Events Footer
======================================*/
#tribe-events-footer { padding: 10px 0;
  &:after { content: ''; display: table; clear: both; }
  li {
    a {
      &:hover { color: darken($primary, 10% )!important;
        i { color: darken($primary, 10% ); }
      }
    }
  }
  .fa-stack {
    .fa-stack-2x { display: none; }
    .fa-stack-1x { color: $primary; }
  }
  .month-name { display: inline-block; }
  .tribe-events-nav-previous {
    a { padding: 0; }
    @media ( min-width: 768px ) {
      .month-name { margin-left: 15px; }
    }

  }
  .tribe-events-nav-next {
    a { padding: 0; }
    @media ( min-width: 768px ) {
      .month-name { margin-right: 15px; }
    }
  }
  li {
    &:not(.event-nav-item ) { display: none; }
  }
}

/* Tribe Events Notifications
======================================*/
.tribe-events-notices { background: transparent; text-align: center; color: $midgray; font-size: 1.2em; border: none; border-radius: 0; padding: 40px 0; margin-left: auto; margin-right: auto;
  @media ( min-width: 768px ) {
    max-width: 70%;
  }
}