
.td-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

.template-directory{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    min-height: 50vh;
    &.nav{
      background-color: $lightgray;
      a{
        margin: 0 1rem 1rem;
      }
    }
  }
}
