.breadcrumb {
  font-size: 1.2em;

  &.dark {
    color: $gray;
  }

  &.page-heading {
    padding: 25px 20px;
  }

  @media(max-width: 767px) {
    display: none;
  }

  a {
    color: $white;
    margin-right: 10px;
    position: relative;
    font-size: .84em;

    &:after {
      content: "";
      background: $white;
      bottom: 0;
      position: absolute;
      left: 0;
      opacity: 0.4;
      height: 1px;
      width: 100%;
    }

    &:last-of-type:after {
      display: none;
    }

  }

  i {
    margin-right: 10px;
  }

  &.dark a {
    color: $gray;

    &:after {
      background: $gray;
    }
  }

}