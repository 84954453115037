/* Fonts */

.font-stack-light {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.font-stack-regular {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.font-stack-medium {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.font-stack-semibold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

/* White/Grays */
$offwhite: #f6f7f7;
$lightgray: #dddddd;
$midgray: #888888;
$gray: #666666;
$darkgray: #55565a;
$white: #ffffff;

// Greens
$green: #406618;

// Blues
$turqoise: #6a747c;
$museum-blue : #6caabb;

// Yellows
$garden-yellow: #829E37;
$golf-yellow: #e5c420;

// Reds
$notice_red : #c41230;