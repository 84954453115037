.callout {

  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  padding: 70px 20px;
  position: relative;

  &:after {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  @media(max-width: 992px) {
    padding: 50px 20px;
  }

  .inner {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    z-index: 1;
  }

  .half {
    width: 50%;

    &:nth-child(1) {
      padding-right: 30px;

      @media(max-width: 992px) {
        padding-right: 15px;
      }

      @media(max-width: 767px) {
        padding: 0;
        order: 2;
        width: 100%;
      }

    }

    &:nth-child(2) {
      padding-left: 97px;

      @media(max-width: 992px) {
        padding-left: 15px;
      }

      @media(max-width: 767px) {
        padding: 0;
        order: 1;
        margin-bottom: 30px;
        width: 100%;
      }

    }

  }

  .image-link {
    color: $accent;
    display: block;
    line-height: 0;
    position: relative;
    text-decoration: none;

    img {
      height: auto;
      max-width: 100%;
    }

    .label {
      background: $white;
      display: block;
      font-size: 1.5em;
      padding: 35px 5px;
      text-align: center;
      width: 100%;
      transition: background 0.2s, color 0.2s;
      &:hover {
        color: $white;
        background-color: $accent_dark;
        i {
          color: $white;
        }
      }

      i {
        margin-left: 10px;
      }
    }
  }

  h3 {

    @extend .font-stack-medium;
    width: 75%;
    font-size: 4.2em;
    line-height: 1.25;
    margin-bottom: 35px;

    @media (max-width: 991px) {
      width: 100%;
    }

  }

  p {
    font-size: 1.5em;
    line-height: 1.7;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
    &.download-button {
      @media(max-width: 767px) {
        text-align: center;
      }
    }
    .button {
      font-size: 1.4rem;

      @media(max-width: 767px) {
        margin: 0 auto;
      }
    }
  }

  em {
    font-style: italic;
    font-weight: bold;
  }

}