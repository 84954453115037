.search-results {

  .content {
    order: 1;
  }

  .sidebar {
    order: 2;

    .sidebar-content {
      border-top: 1px solid $lightgray;
      border-bottom: 0;
    }

  }

  .search-field {

    border-top: 1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
    padding: 10px 0;

    i {
      font-size: 3em;
      color: $lightgray;
      margin-right: 15px;
    }

    form {
      display: inline-block;
      width: 80%;
    }

    input {

      @extend .font-stack-medium;

      border: 0;
      background: transparent;
      color: $primary;
      font-size: 2.4em;
      width: 100%;
    }

  }

  .search-heading {

    @extend .font-stack-medium;

    color: $gray;
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
      font-size: 3em;
      margin-bottom: 30px;
    }

    p {
      font-size: 1.8em;
    }

  }

  .result {
    display: flex;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media(max-width: 767px) {
      flex-flow: row wrap;
    }

    .thumbnail {
      margin-right: 30px;
      width: 30%;
	    overflow: hidden;
	    height: 230px;

      @media(max-width: 767px) {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
	      overflow: hidden;
      }

      img {
        width: 100%;
	      height: 100%;
	      object-fit: cover;
      }
    }

    .post-description {
      width: 70%;
    }

    .post-description h3 a {

      @extend .font-stack-medium;

      color: $primary;
      font-size: 1.8em;

	    &:hover {
		    color: $primary_dark;
	    }

    }

    p {
      font-size: 1.5em;
      line-height: 1.7;
    }

  }

}