.page-content {
  //margin-top: 25px;

  a {
    &:hover { color: $accent_dark; }
  }

  //@media(max-width: 767px) {
  //  margin-top: 0;
  //}

  &.has-sidebar {
    display: flex;
    flex-flow: row wrap;

    .content {
	    width: 75%;
	    @media ( min-width: 992px ) {
		    width: 70%;
	    }


      @media(max-width: 767px) {
        order: 2;
        width: 100%;
        padding: 0 20px 45px;
      }
    }
  }

}

.page-content .content {
  padding: 0 20px 45px;

  @media(max-width: 767px) {
    padding: 45px 20px;
  }
}

// Sidebar Blocks
.page-content .sidebar {
  padding-left: 20px;
	padding-right: 30px;
	@media ( min-width: 992px ) {
		padding-right: 95px;
		width: 25%;
	}
	@media ( min-width: 1200px ) {
		width: 30%;
	}

  @media(max-width: 767px) {
    order: 2;
    padding: 0 20px 25px;
    width: 100%;
  }

  .sidebar-nav {

    a {

      @extend .font-stack-medium;

      border-bottom: 1px solid $lightgray;
      display: block;
      font-size: 1.5em;
      padding: 15px 0;
      color: $darkgray;

      &:hover,
      &.active {
        color: $primary;
      }

    }

    .sub-page-nav {
      padding-left: 20px;
      color: $darkgray;
      a {

        @extend .font-stack-regular;

        border-bottom: 0;

        &:hover,
        &.active {
          color: $primary;
        }

      }

    }

  }

  .event-category {
    border-bottom: 1px solid $lightgray;
    padding-bottom: 20px;

    a {
      align-items: center;
      display: flex;
      font-size: 1.4em;
      font-style: italic;

      img {
        max-width: 49px; max-height: 49px; transform: translateZ(0);
        margin-right: 15px;
      }

    }

  }

  .icon-content {
    border-bottom: 1px solid $lightgray;
    display: flex;
    padding: 15px 0;

    .icon {
      color: $primary;
      font-size: 2em;
      margin-right: 15px;
    }

  }

  .icon-content,
  .sidebar-content {

    p.heading {
      color: $accent_dark;
    }

    p.media-heading { margin-bottom: 20px;
      &:last-of-type { margin-bottom: 20px; }
    }
    .wp-audio-shortcode { margin-bottom: 20px; }

    p {
      font-size: 1.5em;
      line-height: 1.7;
      &:last-of-type { margin-bottom: 0; }
	    a{
		    &.button { margin-top: 0; }
	    }
    }

    strong {
      @extend .font-stack-medium;
      font-weight: 600;
    }

    .button {
      margin-top: 20px;
      padding: 15px 25px;
    }

  }

  .sidebar-content {
    border-bottom: 1px solid $lightgray;
    padding: 15px 0;
  }

}

// WP-Audio
p {
  & + .mejs-container {margin: 10px 0 20px; }
}
.mejs-container { margin-bottom: 20px;}
.mejs-container, .mejs-embed, .mejs-embed body { background: $darkgray;
  .mejs-controls {
    .mejs-time {  }
    .mejs-horizontal-volume-slider {
      .mejs-horizontal-volume-current { background: $primary; }
    }
  }
  .mejs-time-rail {
    .mejs-time-slider {
      &:focus { outline: none; border: none; }
    }
    .mejs-time-current { background: $primary; }
  }
}
.cant-audio-player { float: left; width: 100%;
	&:after { content: ""; display: table; clear: both; }
	.listen-wrap { display: table; height: 100%;
		.listen { font-size: 1.5em; color: $gray; display: table-cell; height: 100%; padding-right: 10px; vertical-align: middle;}
		.audio-trigger { display: table-cell; height: 100%;
			&:hover {
				.fa-circle { color: $white; }
				.fa-stack-1x { color: $accent; }
			}
			.fa-circle { color: $accent; }
			.fa-stack-1x { color: $white; }
		}
	}
}
.block-content {
	.cant-audio-player {
		& + p { margin-bottom: 20px; }
	}
}
.cant-audio-player { margin-bottom: 20px;
	&:last-of-type { margin-bottom: 0; }
	.audio-trigger {
		.fa-stack { width: 5em; height: 5em; line-height: 5em; }
		.fa-circle { font-size: 5em; }
		.fa-stack-1x { font-size: 2.25em; line-height: 2.25em; }
	}
}