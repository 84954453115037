.jPanelMenu-panel {
  &.no-transform { transform: none!important; }
  &.transform {
    .header-container { z-index: 1; }
  }
}

.header-container { position: fixed; width: 100%; z-index: 150;}

/*region Header */
header {

  background: $white;
  position: relative;
  z-index: 2;
  top: 0;
  transition: top 300ms ease-in-out;

  a { cursor: pointer;}

  .notification-banner { background: $notice_red; padding: 20px 0; text-align: center;
    p { color: $white; margin: 0; line-height: 1.4; font-size: 1.8em; font-weight: bold; padding: 0 15px; }
  }

	@media ( max-width: 767px ) {
		.header { padding-left: 15px; padding-right: 15px; }
	}

  .sub-header {
    background: $primary;

    @media(max-width: 767px) {
      display: none;
    }
  }

  .sub-header nav.inner {

    display: flex;
    justify-content: flex-end;
    padding: 0 5px;

    a {

      @extend .font-stack-medium;

      color: $white;
      font-size: 1.4em;
      text-transform: uppercase;
      padding: 12px 15px;
      text-decoration: none;
      line-height: 1;

      &:hover { color: darken($primary_dark, 10%); }

    }

  }

  .header .inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .header .inner .header-left {
    align-items: center;
    display: flex;
  }

  .header .logo {
    max-width: 160px;
    padding: 11px 5px;
    @media(max-width: 992px) {
      padding: 5px 25px;
    }
    img {
      height: auto;
      width: 100%;
    }
  }

  .header .main-nav a {

    @extend .font-stack-medium;

    color: $darkgray;
    font-size: 1.5em;
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.2s;
    padding: 5px 10px;
    line-height: 1;
	  &.search { padding-right: 0; }
    @media ( min-width: 1151px ) {
      padding: 5px 15px; font-size:1.6em;
    }

    > i { font-size: 1.4em; color: $primary; }

    &:hover,
    &.dropdown-open {
      color: $primary;
      > i { color: $primary_dark; }
    }



    @media(max-width: 768px) {
      display: none;

      &.search {
        display: inline;
      }
    }
  }

  .header .mobile-menu-trigger {
    display: none;
    margin-right: 20px;
    width: 25px;

    @media(max-width: 992px) {
      display: flex; flex-flow: row wrap; align-items: center; align-self: stretch; align-content: center;
    }

    span {
      display: block;
      height: 2px;
      background: $primary;
      margin-top: 6px;
      width: 100%;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .main-nav { display: flex; flex-flow: row nowrap; align-self: stretch; align-items: center; padding: 0 0 0 5px;
    > ul:not(.dropdown-menu) { display: flex; align-self: stretch; margin-bottom: 0;
      > li { display: flex; align-self: stretch; align-items: center; float: left; margin: 0; list-style: none; font-size: 1em; cursor: pointer;
	      @media(min-width: 768px) and (max-width: 992px) {
		      display: none;

		      &:nth-child(1),
		      &:nth-child(2),
		      &.search {
			      display: flex;
		      }
	      }
        .dropdown-menu { display: flex; opacity: 0; visibility: hidden; transition: max-height 0.15s ease, opacity 0.2s ease; position: absolute; top: 100%; left: 0; right: 0; width: 100%; max-height: 0; }
        &:hover {
	        > a { color: $primary}
          .dropdown-menu { max-height: 600px; opacity: 1; visibility: visible;
          }
        }
      }
    }
  }

}
/*#endregion */

/*region Dropdown Menus */
//.dropdown-menus {
//
//  position: relative;

  .dropdown-menu:not(.datepicker) { background: $primary; border-top: 2px solid $lightgray; padding: 45px 5px; position: absolute; width: 100%; z-index: 999; cursor: initial;
    .inner { margin: 0 auto; display: flex;
	    @media ( min-width: 992px ){
		    align-items: flex-start!important;
	    }
      .description { padding-right: 45px; width: 30%; }
      .heading { color: $white; margin-bottom: 20px; font-size: 1.4em; }
      p { color: $white; line-height: 1.7; margin-bottom: 25px; }
      .learn-more { color: $white; text-decoration: none; font-size: 1em; padding: 0;
	      i { color: $white; margin-left: 10px; vertical-align: middle; margin-top: -3px; transition: color 0.2s;}
        &:hover { color: darken($primary_dark, 10%);
	        i { color: darken($primary_dark, 10%); }
        }
      }
      nav.links { display: flex; flex-flow: row wrap; width: 70%;
        a { color: $white; display: block; padding: 15px; text-decoration: none; width: 25%; font-size: 1em; text-transform: none;
          &:hover { color: darken($primary_dark, 10%);
            .thumbnail { transform: scale(1.10); box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2); }
          }
          .thumbnail { transition: transform 0.2s, color 0.2s; background-size: cover; background-repeat: no-repeat; background-position: center center; background-color: transparent; margin-bottom: 10px; transform: translateZ(0);
            img { height: auto; max-width: 100%; }
          }
          .title { display: block; text-align: center; }
        }
      }
    }
  }

//}
/*#endregion */

/*region Search Dropdown */
.search-dropdown {
  display: none;
  position: absolute;
  z-index: 3;
  width: 100%;

  .inner {
    align-items: center;
    background: $primary;
    display: flex;
    padding: 0 20px;
    position: relative;
  }

	@media ( min-width: 993px ) {
		.inner:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
			border-bottom: 25px solid $primary;
			right: 0;
			top: -25px;

		}
	}

  form { width: 100%;}

  input {

    @extend .font-stack-regular;

    background: transparent;
    border: 0;
    color: $white;
    font-size: 1.8em;
    padding: 25px 15px;
    outline: 0;
    width: 100%;

    @media ( min-width: 1150px ) {
      font-size: 2.4em;
    }

    &::-webkit-input-placeholder {
      color: $white;
    }

  }
}
/*#endregion */

// Mobile menu overlay.
body[data-menu-position="open"] .jPanelMenu-panel:before {
  background: $primary;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.6;
  width: 100%;
  z-index: 20;
}

// Hide the logo/hamburger icon
//body[data-menu-position="open"] header .header .inner .header-left {
//  visibility: hidden;
//}

body[data-menu-position="closed"] #jPanelMenu-menu {
  display: none;
}
@media (min-width: 1023px ) {
  .jPanelMenu-panel.header-stuck { transform: none !important; }
}
.mobile-menu {
  background: $white;
  display: none;

  .sub-header {
    display: none;

    @media(min-width: 768px) and (max-width: 992px) {
      background: $primary;
      color: $white;
      display: block;
      padding: 15px;
      font-size: 1.4em;
      text-transform: uppercase;
    }
  }

  .menu-header {
    align-items: center;
    border-bottom: 2px solid $lightgray;
    display: flex;
    justify-content: space-between;
    padding: 4px 25px;

    .logo { width: 50%; margin: 0 auto;
      img {
        height: auto;
        max-width: 100%;
      }

    }
    
  }

  .menu-header .mobile-menu-close-trigger {
    color: $primary;
    font-size: 6em;
    text-decoration: none;
    font-weight: 100;
    line-height: 1;
  }

  nav.links {
    > .main-has-dropdown {
      &.dropdown-open {
        > a {
          color: $primary;
        }
      }

      a { width: 100%; align-items: center; display: flex; justify-content: space-between;}

      i {
        font-size: 1.5em;
      }
      .dropdown {
        div {
          &:last-of-type { border-bottom: 2px solid $lightgray; }
        }
      }
    }
    a {
      background: $white;
      color: $darkgray;
      display: block;
      font-size: 1.4em;
      @media (max-width: 767px) {
        padding: 20px 25px;
      }
      padding: 25px;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      z-index: 2;

      &.has-dropdown {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &.dropdown-open {
          color: $primary;
        }

        i {
          font-size: 1.5em;
        }
      }
    }

  }

  nav.links {
    > nav {
      &:not(.dropdown) {
        > a {
          border-bottom: 2px solid $lightgray;
        }
      }
    }
  }

  nav.dropdown {
    display: none;
    position: relative;
    z-index: 1;
  }

  nav.dropdown a {
    color: #333;
    display: block;
    font-size: 1.4em;
    text-transform: none;
    @media ( max-width: 767px ) {
      padding: 0 25px;
      &:first-of-type { padding-top: 25px; padding-bottom: 15px; }
      &:last-of-type { padding-top: 15px; padding-bottom: 25px; }
    }

  }

}