// Colors.
$event-purple: #a55c92;
$event-green: #829E37;
$event-turquoise: #6caabb;
$event-yellow: #e5c420;
$event-orange: #ef8422;
$event-blue: #446b7d;
$event-deeppurple: #532851;
$event-darkgreen: #487135;

.event-purple {
  color: $event-purple;
}

.event-green {
  color: $event-green;
}

.event-turquoise {
  color: $event-turquoise;
}

.event-yellow {
  color: $event-yellow;
}

.event-orange {
  color: $event-orange;
}

.event-blue {
  color: $event-blue;
}

.event-deeppurple {
  color: $event-deeppurple;
}

.event-darkgreen {
  color: $event-darkgreen;
}