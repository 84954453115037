#tribe-events-content {
	.tribe-events-tooltip { padding: 0; border: 1px solid $primary; width: 676px;
		@media ( max-width: 1023px ) {
			width: 476px;
		}
		.tooltip-flex { display: flex; flex-flow: row nowrap; }
		&.tribe-events-tooltip-flipdown {
			&:before { border-bottom-color: $primary; }
		}
		&:not(.tribe-events-tooltip-flipdown) {
			&:before { border: 10px solid transparent; border-top-color: $primary; content: " "; display: block; height: 0; right: 67px; position: absolute; bottom: -21px; width: 0; z-index: 2; }
		}
		.tooltip-left { flex: 45%; padding-right: 2%;
			.tooltip-image { display: flex; overflow: hidden;
				img { height: auto; width: 100%; object-fit: cover; max-height: 343px; }
			}
		}
		.tooltip-right { flex: 55%; padding: 20px 2%; display: flex; align-content: center; flex-flow: row wrap;
			> * { flex: 100%; }
			p { margin-bottom: 0; }
			.tooltip-date { margin-bottom: 5px;
				p { color: $midgray; text-transform: uppercase; font-size: 14px; @extend .font-stack-medium }
			}
			.tooltip-category { margin-bottom: 15px;
				p { font-size: 14px; }
			}
			.tooltip-header { margin: 0 0 20px;
				h4 { margin: 0; color: $gray; font-size: 22px; line-height: 1; @extend .font-stack-medium }
			}
			.tooltip-content { margin: 0 0 20px;
				p { font-size: 14px; }
			}
			.tooltip-learn-more { margin: 0;
				.button { font-size: 11px; border-radius: 2px; padding: 10px 15px; color: $white; }
			}
		}
	}
}