.event-search {

  padding: 40px 20px;

  .inner {
    display: flex;
    flex-flow: row wrap;
  }

  label {

    @extend .font-stack-medium;

    display: block;
    font-size: 1.8em;
    margin-bottom: 20px;

  }

  input {

    @extend .font-stack-medium;

    background: $offwhite;
    border: 0;
    color: $darkgray;

    font-size: 1.4em;
    padding: 16.5px 15px;
    width: 100%;

    &[type="date"] {
      padding: 15px 15px;
    }
  }

  .search-field {
    padding-right: 70px;
    width: 30%;

    @media(max-width: 992px) {
      padding-right: 0;
      width: 50%;

      &:first-child {
        padding-right: 15px;
      }

      &:last-child {
        padding-left: 15px;
      }

    }

    @media(max-width: 767px) {
      width: 100%;

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-left: 0;
      }
    }

  }

  .search-actions {
    padding-top: 51px;
    width: 20%;

    @media(max-width: 992px) {
      order: 4;
      padding-top: 30px;
      width: 50%;
    }

    .button {
      padding: 15px 25px;
    }

  }

  .toggle-view {
    width: 15%;

    @media(max-width: 992px) {
      padding-top: 30px;
      width: 50%;
    }

    a {
      color: $darkgray;
    }

    i {
      color: $lightgray;
    }

    .view-label {

      @extend .font-stack-medium;

      align-items: center;
      display: flex;
      color: $lightgray;
      font-size: 1.4em;
      text-transform: uppercase;

      i {
        font-size: 1.8em;
        margin-right: 15px;
      }

    }

  }

}