.display-none {
  display: none!important;
}

.display-block {
  display: block!important;
}

.display-flex {
  display: flex!important;
}

.animated {
  animation-duration: 0.2s;
}

a {
  color: $accent;
  text-decoration: none;
  &:hover { color: $accent_dark; }
}

b, strong { font-weight: bold; }
em { font-style: italic; }
small { font-size: 85%; }
ul { margin-bottom: 20px;
  li { margin-left: 2em; list-style: disc; margin-bottom: 0.5em; font-size: 1.5em; }
}
p { margin-bottom: 1em;
  &:empty { visibility: hidden; }
}

input:-webkit-autofill { box-shadow: inset 0 0 0 9999px $primary; color: $white;}

.cls-1 {
  fill: $primary;
  fill-rule: evenodd;
}
.svg-white {
  fill: $white;
  fill-rule: evenodd;
}