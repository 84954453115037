.logged-in {
	#main {
		&.neg-margin { margin-top: -32px; }
		@media ( max-width: 782px ) {
			&.neg-margin { margin-top: -46px; }
		}
	}
}
.jPanelMenu-panel {
	> main { position: relative;
		&.menu-open { position: relative; z-index: 2;}
	}
}

#main {
	&.overlay {
		&:after { content: ''; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
	}
}