.block {
	margin-bottom: 30px; padding-bottom: 30px;

	&:last-of-type { padding-bottom: 0; margin-bottom: 0; }

	border-bottom: 1px solid $lightgray;

  &:last-of-type { border-bottom: none; }

	h1,h2,h3,h4,h5,h6 { @extend .font-stack-medium;	}

	h1 { font-size: 4em; }
	h2 { font-size: 3em; }
	h3 { font-size: 2.5em; color: $primary; }
	h4 { font-size: 2em; color: $primary; }
	h5 { font-size: 1.5em; }
	h6 { font-size: 1em; }

	hr { border: none; border-bottom: 2px solid $lightgray; }


  .iframe-wrapper { position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;
    iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
  }

  .grey-sub-heading { text-transform: uppercase; margin-bottom: 10px!important; @extend .font-stack-medium; }
  .link-sub-heading { text-transform: uppercase; @extend .font-stack-medium; margin-bottom: -20px; }

}

.block-content {
  margin-bottom: 30px;

  h1,h2,h3,h4,h5,h6 {

    @extend .font-stack-medium;

    margin: 15px 0 20px;
  }

  p {
    font-size: 1.5em;
    line-height: 1.5;
    margin-bottom: 20px;
	  &:last-of-type { margin-bottom: 0; }

  }

  img {
    height: auto;
    max-width: 100%;
  }

  blockquote {
    color: $primary;
    font-size: 1.8em;
    line-height: 1.7;
    margin-bottom: 10px;
  }

  p.caption {
    font-size: 1.2em;
  }

  strong {
    font-weight: 600;
  }

  .heading {
    color: $primary;
    font-size: 2.5em;
  }

  .alignnone{margin:5px 20px 20px 0}
	.aligncenter,div.aligncenter{display:block;margin:5px auto}
	.alignright{float:right;margin:5px 0 20px 20px}
	.alignleft{float:left;margin:5px 20px 20px 0}
	a img.alignright{float:right;margin:5px 0 20px 20px}
	a img.alignleft,
	a img.alignnone{margin:5px 20px 20px 0}
	a img.alignleft{float:left}
	a img.aligncenter{display:block;margin-left:auto;margin-right:auto}
	.wp-caption{background:$white;border:1px solid #f0f0f0;max-width:96%;padding:5px 3px 10px;text-align:center}
	.wp-caption.alignleft,.wp-caption.alignnone{margin:5px 20px 20px 0}
	.wp-caption.alignright{margin:5px 0 20px 20px}
	.wp-caption img{border:0;height:auto;margin:0;max-width:98.5%;padding:0;width:auto}
	.wp-caption p.wp-caption-text{font-size:11px;line-height:17px;margin:0;padding:0 4px 5px}
	.screen-reader-text{clip:rect(1px,1px,1px,1px);position:absolute!important;white-space:nowrap;height:1px;width:1px;overflow:hidden}
	.screen-reader-text:focus{background-color:#f1f1f1;border-radius:3px;box-shadow:0 0 2px 2px rgba(0,0,0,.6);clip:auto!important;color:#21759b;display:block;font-size:14px;font-size:.875rem;font-weight:700;height:auto;left:5px;line-height:normal;padding:15px 23px 14px;text-decoration:none;top:5px;width:auto;z-index:100000}

  &#threed-space {
    p { position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;
      iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
    }
  }

}

.block-page-heading {

  @extend .font-stack-medium;

  border-bottom: 1px solid $lightgray;
  margin-bottom: 30px;
  padding-bottom: 30px;

  @media(max-width: 992px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  h2 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.8em;
    line-height: 1.7;
	  margin-bottom: 0;
  }

}

.block-photo {

  margin: 0 auto 30px;
  max-width: 750px;

  .caption {
    color: $gray;
    font-size: 1.5em;
  }

  .slide-counter {
    margin-bottom: 20px;

    @media(max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .caption-content {
    font-style: italic;
    line-height: 1.7;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .photo-lightbox-trigger {
    display: block;
    position: relative;

    .overlay {
      background: rgba(0, 0, 0, 0.2);
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4em;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      transition: opacity 0.2s;

      i {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        line-height: 110px;
        text-align: center;
        height: 110px;
        width: 110px;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }

  }

}

.block-photo-carousel {

  .photos-header { margin-bottom: 45px;}

  .photos {
    margin-bottom: 20px;
    position: relative;

    @media(max-width: 767px) {
      margin-bottom: 10px;
    }

    .slide-nav {
      background: rgba(0, 0, 0, 0.8);
      color: $white;
      top: 0;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      position: absolute;
      height: 100%;
      width: 65px;
      z-index: 999999999999999999999999999999;
      transition: opacity 0.2s;
    }

    .slide-nav.prev {
      left: 0;
    }

    .slide-nav.next {
      right: 0;
    }

    &:hover .slide-nav {
      opacity: 1;
    }

  }

}

.block-lightbox-container {

  .block-photo .caption {
    color: $white;
  }

}

.block-list {

  margin-bottom: 30px;

  h3 { padding-bottom: 20px;}

  .list-item {

    color: $gray;
    font-size: 1.5em;
    line-height: 1.7;
    padding: 20px 25px;

    &:nth-of-type(odd) {
      background: $offwhite;
    }

  }

}

.block-accordion {

  margin-bottom: 30px;
  border-bottom: none;

  .qa {
    &:first-of-type {
      .title { padding-top: 0; }
    }
  }

  .title {

    @extend .font-stack-medium;

    border-bottom: 1px solid $lightgray;
    color: $primary;
    cursor: pointer;
    display: flex;
    font-size: 2em;
    justify-content: space-between;
    padding: 30px 0;

  }

  .message {
    display: none;
    font-size: 1em;
    border-bottom: 1px solid $lightgray;
    color: $gray;
    line-height: 1.7;
    padding: 20px 25px;

    p, ul li {
      font-size: 1.5em;
    }
	  p { margin-bottom: 20px;
	    &:last-of-type { margin-bottom: 0; }
	  }
    a { font-size: 1em;}

  }

}

.block-featured-list {

  margin-bottom: 30px;

  .row {
    border-bottom: 1px solid $lightgray;
    margin-bottom: 40px;
    display: flex;


    @media(max-width: 767px) {
      flex-flow: row wrap;
    }

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
    }

  }

  .description {
    width: 60%;

    @media(max-width: 767px) {
      order: 2;
      width: 100%;
    }

    //h3 {
    //
    //  @extend .font-stack-medium;
    //
    //  color: $primary;
    //  font-size: 1.8em;
    //  margin-bottom: 20px;
    //}

    p {
      color: $gray;
      font-size: 1.5em;
      line-height: 1.7;
      margin-bottom: 20px;
	    &:last-of-type { margin-bottom: 0; }
    }
    .grey-sub-heading { margin-bottom: 10px!important; }

  }

  .thumbnail {
    margin-left: 25px;
    width: 40%;

    @media(max-width: 767px) {
      order: 1;
      margin-left: 0;
      margin-bottom: 30px;
      width: 100%;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

}

.block-form-accordion {
	h1,h2,h3,h4,h5,h6 { @extend .font-stack-medium; margin-bottom: 30px; }

	p { font-size: 1.5em; line-height: 1.5; margin-bottom: 20px;
		&:last-of-type { margin-bottom: 0; }
	}
	strong { font-weight: 600; }
	.form-accordion { padding: 25px 0 20px; }
}

.fancybox-slide--iframe {
  .fancybox-content {
    @media ( max-width: 767px ) {
      background: transparent;
      iframe { background: transparent;
      }
    }
    iframe {}
  }
}

.block-section-heading { margin-bottom: 30px; padding-bottom: 30px;
}