.home {
  .featured-events {
    .events-list {
      a {
        &:hover {
          .thumbnail { transform: scale(1.1);
            img { transform: translateZ(0); }
          }
        }
      }
    }
  }
}
.featured-events {

  padding-bottom: 70px;

  @media(max-width: 767px) {
    padding-bottom: 35px;
  }

  .inner {
    margin-top: -130px;

    @media(max-width: 1150px) {
      padding: 0 20px;
      margin-top: -100px;
    }

    @media(max-width: 767px) {
      margin-top: -160px;
    }

  }

  .events-list {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 0 -15px;
    @media ( min-width: 768px ) and ( max-width: 1023px ) {
      flex-flow: row wrap;
      .event { margin-bottom: 30px;
        &:nth-child(3),
        &:nth-child(4){ margin-bottom: 0; }
      }
    }
  }

  h2 {

    @extend .font-stack-medium;

    color: $white;
    font-size: 1.8em;
    margin-bottom: 30px;
    position: relative;

    @media(max-width: 767px) {
      text-align: center;
    }

  }

  .events-list .event {
    text-decoration: none;
    transition: transform 0.2s, color 0.2s;
    padding: 0 15px;
    float: left;
    @media ( min-width: 768px ) {
      width: 50%;
    }
    @media ( min-width: 1023px ) {
      width: 25%;
    }

    @media(max-width: 767px) {
      width: 100%;
    }

    .thumbnail {
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 25px;
      transition: transform 0.2s, color 0.2s;

      img {
        height: auto;
        width: 100%;
      }

    }

    span {
      display: block;
    }

    .date {
      color: #555;
      font-size: 1.4em;
      margin-bottom: 5px;
      text-transform: uppercase;

      @media(max-width: 767px) {
        text-align: center;
      }

    }

    .title {
      color: #333;
      font-size: 1.5em;
      transition: color 0.2s;

      @media(max-width: 767px) {
        text-align: center;
      }

    }

    &:hover {

      .title {
        color: $accent_dark;
      }

    }

  }

  .events-pager {
    font-size: 40px;
    font-family: serif;
    text-align: center;

    span {
      color: $lightgray;
      margin-right: 5px;
      cursor: pointer;

      &.cycle-pager-active {
        color: $midgray;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

  }

}

.featured-events.events-page {
  background: $primary;
  padding: 70px 20px;

  .inner {
    margin-top: 0!important;
  }

  h2 {
    font-size: 2.8em;
    margin-bottom: 30px;
    text-align: center;
  }

  .events-list .event .event-card {
    background: $white;
    padding: 25px;
    position: relative;
    bottom: 0;
    @media ( min-width: 768px ) {
      height: 165px;
      transition: all 0.3s ease-in-out;
    }
    .date {
      color: $midgray;

      @media(max-width: 767px) {
        text-align: left;
      }

    }

    .event-category {
      font-size: 1.2em;
      font-style: italic;
      margin-bottom: 20px;
    }

    .title {
      color: $darkgray;

      @media(max-width: 767px) {
        text-align: left;
      }
    }

    .button {
      display: inline-block;
      text-align: center;
      margin-top: 20px;
      padding: 15px 25px;
      @media ( min-width: 768px ) {
        opacity: 0;
      }
    }

  }

  .events-list .event {
    position: relative;
    @media (min-width: 768px) {
      .event-card {
        &.active {
          .button { opacity: 1; transition: opacity 0.3s ease-in; transition-delay: 0.15s; }
        }
      }
    }

    .thumbnail {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      &:hover {
        .event-card { height: 225px; margin-top: -59px;
          &:after { content: ''; display: block; height: 10px; background: $white; z-index: 20; position: absolute; left: 0; right: 0; bottom: 0; }
        }
        .title {
          color: $accent_dark;
        }
      }
    }
  }

}

.single-tribe_events {
	.featured-events{
		&.events-page {
			@media (max-width: 992px) {
				margin: 0 -20px;
			}
		}
	}
	.page-content{
		&.has-sidebar {
			@media ( max-width: 992px ) {
				.sidebar { order: 2; }
				.content { order: 1;}
			}
			.sidebar { order: 1; }
			.content { order: 2;}
		}
	}
	footer { margin-top: 0; }
}