#tribe-events-content {
	.tribe-events-month-event-title { border-bottom: 1px solid $lightgray;
		&:last-of-type { border-bottom: 0; }
		a { font-size: 13px;
			&:hover { color: $primary; }
		}
	}
}
#tribe-events-content {

.tribe-mobile-day-heading { background: $primary; color: $white; padding: 20px 0; text-align: center; margin-top: 20px; margin-bottom: 0; font-size: 2.4em; @extend .font-stack-medium}

/* Popdown Calendar Content
======================================*/
.popdown-container { border-bottom: 1px solid $darkgray; margin-bottom: 20px; padding-bottom: 20px;
	.popdown-image {
		img { width: 100%; height: auto; }
	}
	.popdown-content {				h4 { margin: 0; color: $gray; font-size: 1.8em; @extend .font-stack-medium; }
		}
		.popdown-date { margin-bottom: 10px;
			p { font-size: 14px; @extend .font-stack-medium; }
		}
		.popdown-category { margin-bottom: 10px;
			p { }
		}
		.popdown-excerpt { margin-bottom: 10px;}
		.popdown-learn-more { margin-bottom: 0;
			.button { margin-bottom: 0; font-size: 11px; border-radius: 2px; padding: 10px 15px; color: $white; }
		}
	}
	&:first-of-type { padding-top: 0; }
	&:last-of-type { border-bottom: 0; }
}

@media ( max-width: 767px ) {
	#tribe-events-footer, #tribe-events-header {
		&:before, &:after { display: none; }
	}


	#tribe-events-content {
		.tribe-events-calendar {
			.mobile-active { color: $white; background-color: $primary;
				div[id*=tribe-events-daynum-] { color: $white; background-color: $primary; }
				&:hover { background-color: $primary; }
			}
		}
	}
}

.post-type-archive-tribe_events footer { margin-top: 0; }
#tribe-events-content {
	.tribe-events-viewmore {
		> a { font-size: 1em; color: $accent;
			&:hover { color: $accent_dark; }
		}
	}
}