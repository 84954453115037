.content-carousel {

  background: $offwhite;
  padding: 50px 20px;

  @media(max-width: 767px) {
    padding: 0;
  }

  nav.carousel-nav {
    margin-bottom: 40px;

    @media(max-width: 767px) {
      display: none;
    }
  }

  nav.carousel-nav a {

    @extend .font-stack-medium;

    color: $darkgray;
    font-size: 1.4em;
    margin-right: 30px;
    text-transform: uppercase;
    text-decoration: none;
	  display: inline-block;

	  &:after { content: ''; display: block; margin: auto; height: 5px; width: 0; background: transparent; transition: width .3s ease, background-color .3s ease;}

	  &:hover,&.active {
		  &:after { width: 100%; background: $primary_dark
		  }
	  }

    //&.active {
    //  padding-bottom: 5px;
    //  border-bottom: 5px solid $primary;
    //}

    &:last-of-type {
      margin-right: 0;
    }

  }

  .content-slides {
    padding-bottom: 50px;

    @media(max-width: 767px) {
      padding-bottom: 0;
    }

  }

  .content-slide {

    display: flex!important;
    flex-flow: row wrap;
    height: auto;
    opacity: 0;

    &.first {
      opacity: 1;
    }

    @media(max-width: 767px) {
      opacity: 1;
    }

    .mobile-slide-label {
      border-top: 1px solid $lightgray;
      display: none;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2em;
      text-transform: uppercase;
      padding: 20px;
      width: 100%;

      &.slide-open {
        color: $primary;
      }

      @media(max-width: 767px) {
        display: flex;
      }

      i {
        font-size: 1.5em;
      }
    }

  }

  .content-slide .content-half {
    color: $darkgray;
    padding-right: 62.5px;
    width: 50%;

    @media(max-width: 992px) {
      padding: 0;
      width: 100%;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 62.5px;

      @media(max-width: 992px) {
        margin-top: 40px;
	      margin-bottom: 40px;
        padding: 0;
      }

      @media(max-width: 767px) {
        padding: 0 20px;
      }
    }

    @media(max-width: 767px) {
      display: none;
      padding: 0 20px;
    }

    h3 {
      font-size: 3em;
      margin-bottom: 25px;
    }

    h4 {

      @extend .font-stack-medium;

      color: $primary;
      font-size: 1.8em;
      margin-bottom: 30px;

    }

    p {
      font-size: 1.5em;
      line-height: 1.7;
      margin-bottom: 30px;
    }

    strong {
      font-weight: 600;
    }

    .split-content {
      display: flex;

      .split {
        padding: 0 20px;
        width: 50%;
      }
    }

    iframe {
      width: 100%;

      @media(max-width: 992px) {
        margin-bottom: 50px;
      }

    }

    table {
      margin-bottom: 20px;
      table-layout: fixed;
      width: 100%;

      .heading {
        background: $primary;
        color: $white;
      }

      .heading td {

        @extend .font-stack-semibold;

        font-size: 1.4em;
        padding: 20px;
        word-wrap: break-word;
      }

      tbody td {
        font-size: 1.4em;
        @media ( min-width: 992px ) {
          font-size: 1.5em;
        }
        padding: 20px;
        word-wrap: break-word;
      }

      tbody tr:nth-child(even) {
        background: $white;
      }

    }

    .slide-actions {
      align-items: center;
      display: flex;

      @media(max-width: 767px) {
        display: block;
        text-align: center;

        .button {
          display: inline-block;
          text-align: center;
        }
      }

    }

  }

  .content-slide .weather {
    margin-left: 40px;

    .temperature {
      border-right: #333 1px solid;
      font-size: 7.2em;
      padding-right: 25px;
      margin-right: 25px;

      sup {
        vertical-align: super;
        font-size: 3rem;
        position: relative;
        top: -5px;
      }
    }

  }

  .content-slide nav.graphic a {
      &.gardens {
        &:hover { background: $garden-yellow !important; }
      }
      &.museum {
        &:hover { background: $museum-blue !important; }
      }
      &.golf {
        &:hover { background: $golf-yellow !important; }
      }
    @extend .font-stack-medium;

    color: $white;
    display: block;
    font-size: 2.8em;
    margin-bottom: 10px;
    padding: 12px 5px;
    text-align: center;
    text-decoration: none;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center center!important;

    @media ( min-width: 768px ) {
      padding: 30px 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .label .icon {
      margin-right: 20px;
    }

  }


}