.hero {

  @extend .font-stack-medium;

  align-items: center;
  color: $white;
  display: flex;
  height: 565px;
  padding: 0 25px;
  position: relative;
  background-position: center center;
  background-repeat:no-repeat;
  background-size: cover;

  &:after {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  @media(max-width: 767px) {
    height: 535px;
  }

	& + .inner {
		margin-top: 40px;
	}

  .hero-content {

    @extend .inner;

    margin: -130px auto 0!important;
    position: relative;
    width: 100%;
    z-index: 1;

    @media(max-width: 992px) {
      margin: -100px auto 0!important;
    }

    @media(max-width: 767px) {
      margin: -160px auto 0!important;
      text-align: center;
    }

  }

  .hero-content h1 {
    font-size: 4.8em;
    margin-bottom: 40px;
    line-height: 1.25;

    @media(min-width: 768px) and (max-width: 992px) {
      font-size: 4.1em;
    }

    @media(max-width: 767px) {
      font-size: 3em;
      margin-bottom: 30px;
      text-align: left;
    }

  }

  .hero-content .button {

    @media(max-width: 767px) {
      display: inline-block;
      text-align: center;
    }

  }

}

.hero.small {
  border-bottom: 2px solid $lightgray;
  height: 410px;
  padding: 20px 20px 45px;
  //text-shadow: 2px 2px #555;

  @media(max-width: 767px) {
    height: auto;
    padding: 35px 20px;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 0!important;

    @media(max-width: 767px) {
      display: block;
    }

  }

  .hero-content h1 {
    font-size: 42px;
    margin-bottom: 20px;

    @media(max-width: 767px) {
      font-size: 3em;
    }

  }

  .hero-content p {
    font-size: 18px;
    line-height: 30px;
    max-width: 485px;

    @media(max-width: 767px) {
      font-size: 1.6em;
    }
  }

}

.home {
  .hero {
    &:before {
      content: ''; display: block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; @extend .overlay;
    }
  }
}