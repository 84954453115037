.event-categories {
  .category-list {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;

    @media(max-width: 992px) {
      display: none;

      &.display-block {
        background: $white;
        display: flex!important;
      }
    }

  }

  .category-list a {
    display: block;
	  border-right: 1px solid $lightgray;
    padding: 15px 30px 5px 30px;
    position: relative;
    text-align: center;
    transition: background 0.2s, transform 0.2s;
    width: 12.5%;

	  &:hover,
	  &.active{
		  border-left: 1px solid $lightgray;
	  }

    img {
      height: 50px; width: 50px;
      filter: none;
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.0,1.0);
    }

    @media(max-width: 992px) {
      border: 0;
      width: 33.33333334%;
    }

    @media(max-width: 767px) {
      width: 50%;
    }

    &:last-child {
      border-right: 0;
    }

  }

  .category-list a span {
    display: block;
    font-size: 1.4em;
    font-style: italic;
    margin-top: 10px;
  }

  .category-list a span:not(.icon) {
    margin: 0 -20px;
  }

  .category-list a .icon {
    width: 100%;
    text-align: center;
    opacity: 0;
    color: $lightgray;
    margin-top: 0;
    transition: opacity 0.2s;
    font-size: 4em;
    line-height: 1;
  }

  .category-list a.active {
    background: $white;
    box-shadow: 0px 6px 5px 1px rgba(0,0,0,0.2);
    transform: scale(1.10);

    .icon { display: none;
      @media ( min-width: 992px ) {
        display: block;
        opacity: 1;
      }
    }

  }

  @media(min-width: 992px) {

    .category-list a:hover {
      background: $white;
      box-shadow: 0px 6px 5px 1px rgba(0,0,0,0.2);
      transform: scale(1.10);

      .icon {
        opacity: 1;
      }

    }

  }

  .event-categories-mobile-trigger {
    display: none;
    border-bottom: 1px solid $lightgray;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 20px;
    width: 100%;

    i {
      font-size: 1.5em;
    }

    &.category-open {
      color: $primary;
    }

    @media(max-width: 992px) {
      display: flex;
    }
  }

}