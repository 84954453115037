// Colors
$primary : #9a3428;
//$primary_dark : #772118;
$primary_dark : darken( $primary, 20%);
$accent : #b8922f;
$accent_dark : darken( $accent, 5% );

// Home Hero Overlay
.overlay {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9a3428+0,000000+100&0.9+0,0+35 */
	background: -moz-linear-gradient(left,  rgba(154,52,40,0.9) 0%, rgba(100,34,26,0) 35%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(154,52,40,0.9) 0%,rgba(100,34,26,0) 35%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(154,52,40,0.9) 0%,rgba(100,34,26,0) 35%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e69a3428', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
}