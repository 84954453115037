.event-list {
  padding: 50px 20px;

  .event-preview {
    align-items: center;
    display: flex;
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media(max-width: 767px) {
      display: block;
    }

  }

  .thumbnail {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    margin-right: 60px;

    img {
      height: auto;
      width: 100%;
    }

    @media(max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;

    }
  }
	@media ( min-width: 993px ) {
		.event-summary {
			width: 70%;
		}
	}

  .date {

    @extend .font-stack-medium;

    color: $midgray;
    display: block;
    font-size: 1.4em;
    margin-bottom: 10px;
    text-transform: uppercase;

  }

  .event-category {
    font-size: 1.4em;
    font-style: italic;
    margin-bottom: 20px;
  }

  p {
    color: $turqoise;
    font-size: 1.4em;
    line-height: 1.7;
    margin-bottom: 20px;
  }

  h3 a {
    color: $turqoise!important;
    font-size: 2.5em!important;
    margin-bottom: 20px!important;
  }

  .button {
    padding: 15px 25px;
    color: $white!important;
  }

  .pagination {
    padding: 70px 20px 0;
    text-align: center;
    width: 100%;

    @media(max-width: 767px) {
      padding: 45px 20px;
    }

    .button {
      padding: 25px 60px;
    }

  }

}