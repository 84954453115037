.hero + .page-nav {
	@media( min-width: 1024px ) {
		margin-bottom: 40px;
	}
}

.page-nav {

  border-bottom: 1px solid $lightgray;
  margin-bottom: 0px;

  .page-nav-mobile-trigger {
    display: none;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 20px;
    width: 100%;

    &.slide-open {
      color: $primary;
      border-bottom: 1px solid $lightgray;
    }

    @media(max-width: 992px) {
      display: flex;
    }

    i {
      font-size: 1.5em;
    }
  }

  nav {
    display: flex;
    flex-flow: row wrap;

    @media(max-width: 992px) {
      display: none;
    }

  }

  nav a {

    @extend .font-stack-medium;

    //border-bottom: 5px solid transparent;
    color: $midgray;
    display: block;
    font-size: 1.3em;
    padding: 45px 10px 40px;
    text-transform: uppercase;
	  position: relative;

    &:hover { color: $primary; }

    @media(max-width: 992px) {
      padding: 15px 20px;
      width: 100%;
      text-align: center;
    }
		@media ( min-width: 993px ) {
			&:after { content: ''; display: block; margin: auto; height: 5px; width: 0; background: transparent; transition: width .3s ease, background-color .3s ease; position: absolute; bottom: 0; left: 0; right: 0; }

			&:hover,&.active {
				&:after { width:100%; background: $primary
				}
			}

		}



  }

}