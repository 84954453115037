.content-grid {
  padding: 70px 20px;

  @media(max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  h3 {

    @extend .font-stack-medium;
    font-size: 3em;
    margin-bottom: 30px;
    text-align: center;

  }

  p.heading {
    font-size: 1.5em;
    line-height: 1.7;
    text-align: center;
    font-weight: bold;
  }

  .grid-actions {
    margin-top: 40px;
    text-align: center;
  }

  .grid-items {
    display: flex;
    flex-flow: row wrap;
    margin-top: 30px;
  }

  .grid-items .item {
    text-align: center;
    padding: 0 15px;
    width: 25%;

    @media(max-width: 992px) {
      margin-bottom: 30px;
      width: 50%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media(max-width: 767px) {
      margin-bottom: 40px;
      width: 100%;
    }

    .icon-container { width: 85px; height: 85px; border-radius: 50%; background-color: $primary; margin: 0 auto 30px; display: flex; align-items: center; justify-content: center;
      img { margin-bottom: 0; transform: translateZ(0); height: 60px; width: 60px; }
    }

    h4 {
      font-size: 1.8em;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.5em;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      .fa { margin-left: 5px; }
    }

  }

}