.contact-social-media { background: $primary; padding: 70px 0;
	.social-media-container { @extend .inner;
		> div { color: $white; text-align: center; }
		p { margin-bottom: 0; }
		.social-media-sub-header {
			p { font-size: 15px; font-style: italic; margin-bottom: 0; }
		}
		.social-media-header { margin-bottom: 20px;
			p { font-size: 28px; @extend .font-stack-medium; margin-bottom: 0; }
		}
		.social-media-content { margin: 0 auto 40px;
			@media ( min-width: 1024px ) {
				max-width: 40%;
			}
			p { font-size: 15px; }
		}
		.social-media-container { display: flex; flex-flow: row wrap; align-items: center;
			@media ( min-width: 768px ) {
				flex-flow: row nowrap;
				justify-content: space-around;
			}
			.item { padding: 15px 0;
				.social-media {
					a {
						&:hover {
							i { color: $lightgray; }
						}
						i, p { text-align: center; color: $white; }
						i { font-size: 42px; margin-bottom: 10px; }
						p { margin-bottom: 0; font-size: 15px; font-weight: 300;
							&:last-of-type { @extend .font-stack-medium; }
						}
					}
				}
				&.one { width: 100%; }
				&.two,
				&.four { width: 50%; }
				&.three,
				&.five { width: 33.3333%; }
				@media (min-width: 768px ) {
					border-left: 1px solid $white; padding: 30px 0;
					&:first-of-type { border: none; }
					&.one { width: 100%; }
					&.two { width: 50%; }
					&.three { width: 33.3333%; }
					&.four { width: 25%; }
					&.five { width: 20%; }
				}
			}
		}
	}
}