.home {
	footer { margin-top: 0;}
}
footer {
  background: $offwhite;
  padding: 45px 0;
  margin-top: 55px;
  @media(max-width: 767px) {
    padding-top: 15px;
    margin-top: 30px;
  }

  .top {

    align-items: center;
    border-bottom: 1px solid $lightgray;
    display: flex;
    justify-content: space-between;
    padding-bottom: 45px;

    @media(min-width: 768px) and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media(max-width: 767px) {

      border-bottom: 0;
      flex-flow: row wrap;
      padding-bottom: 15px;

      .logos,
      .search {
        width: 100%;
      }

      .search {
        margin-top: 15px;
        padding: 0 45px;

        input {
          width: 100%;
        }
      }

    }

  }

  .top .logos {
	  align-items: flex-end;
    @media ( max-width: 767px ) {
	    align-items: center;
    }
    display: flex;

    @media(max-width: 767px) {
      display: block;
      text-align: center;
    }

    a {
      border-right: 1px solid $lightgray;
      line-height: 0;
      margin-right: 25px;
      padding-right: 25px;
      text-decoration: none;

      @media(max-width: 767px) {
        display: block;
        border-right: 0;
        margin-right: 0;
        padding-right: 0;

        &:first-child {
          margin-bottom: 15px;
        }

      }

      &:last-of-type {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
    }

  }

  .top .search {
    position: relative;
  }

  .top .search input {

    @extend .font-stack-medium;

    border: 0;
    border-radius: 5px;
    color: $primary;
    font-size: 1.5em;
    min-width: 290px;
    outline: 0;
    padding: 20px 60px 20px 25px;
    background: $white;

  }

  .top .search button {
    background: transparent;
    border: 0;
    color: $primary;
    font-size: 2em;
    position: absolute;
    right: 25px;
    margin-top: -14.5px;
    top: 50%;

    @media(max-width: 767px) {
      right: 60px;
    }
  }

  .bottom {
    color: $darkgray;
    display: flex;
    flex-flow: row wrap;
    font-size: 1.5em;
    padding-top: 45px;

    @media(min-width: 768px) and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media(max-width: 767px) {
      padding-top: 0;
    }

    nav.footer a {

      @extend .font-stack-medium;

      color: $darkgray;
      display: block;
      margin-bottom: 5px;
      text-decoration: none;

      &:hover { color: $primary; }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .col {
	    padding-right: 20px;
	    @media ( min-width: 1200px ) {
		    padding-right: 70px;
	    }
      width: 25%;

      @media(min-width: 768px) and (max-width: 992px) {
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 45px;
        }

      }

      @media(max-width: 767px) {
        border-bottom: 1px solid $lightgray;
        padding: 0 30px;
        width: 100%;
      }

      .col-content {

        @media(max-width: 767px) {
          display: none;
          padding-bottom: 20px;
        }

      }

    }

    .col .heading {

      @extend .font-stack-medium;

      display: block;
      text-transform: uppercase;

      &.top-heading {
        margin-bottom: 20px;

        i { display: none; }

        &.panel-open {
          color: $primary;
        }

        @media(max-width: 767px) {

          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
          margin-bottom: 0;

          i {
            display: inline-block;
            font-size: 1.5em;
          }
        }

      }

    }

    .col p {
      @extend .font-stack-regular;
      display: block;
      font-size: 1.4rem;
      margin-bottom: 15px;
    }

    .location .row {
      display: flex;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .heading {
        margin: 5px 0;
      }

      .icon {
        padding-right: 20px;
      }

      .address {
        @extend .font-stack-regular;
        display: block;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }

      a {

        @extend .font-stack-medium;

        color: $primary;
        text-decoration: none;
        &:hover { color: $primary_dark; }
      }

      &.contact a {
        color: $darkgray;
        &:hover { color: $primary; }
      }

    }

    .visitor-info {

      .heading {
        margin-bottom: 20px;
      }

    }

    .scoop {

      @extend .font-stack-regular;

      a {

        @extend .font-stack-medium;

        color: $primary;
        text-decoration: none;

        &:hover { color: $primary_dark; }

      }

      nav.social {
        margin-bottom: 30px;
      }

      nav.social a {
        color: $primary;
        font-size: 2.3rem;
        margin-right: 20px;

        &:hover{ color: $primary_dark; }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .heading {
        margin-bottom: 30px;
      }

      strong {
        font-weight: 600;
      }

    }



  }

}

.copyright {

  @extend .font-stack-regular;

  background: $primary;
  color: $white;
  font-size: 1.5em;
  padding: 25px 5px;

  @media(min-width: 768px) and (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media(max-width: 767px) {
    padding: 25px 35px;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover { color: darken($primary_dark, 10%); }

  }
}