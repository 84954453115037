.nf-table-display {
  width: 100%;
}

.nf-form-fields-required { display: none; }

.nf-form-content {
  select { height: 59px; }
}

label {

  @extend .font-stack-medium;

  color: $gray;
  font-size: 1.4em;
  text-transform: uppercase;
}

input, textarea, select {

  background: $offwhite;
  border: 1px solid transparent;
  font-size: 1.4em;
  padding: 20px 10px;
  width: 100%;
  outline: 0;

  &:focus {
    border-color: $primary;
  }
}

input[type="button"] {

  @extend .button;

  background: $accent;
  color: #fff;

  &:hover { background: $accent_dark; }

}

@media ( max-width: 767px ) {
	.nf-form-cont {
		.one-half,
		.one-third,
		.three-sixths,
		.two-sixths,
		.two-fourths { width: 100%; margin-left: 0; margin-right: 0; }
	}
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;}

.list-select-wrap {
	.nf-field-element {
		&:after { content: '\F078'; display: block; width: 20px; height: 20px; font-size: 20px; line-height: 20px; color: $gray; font: normal normal normal 14px/1 FontAwesome; position: absolute; top: 33%; right: 20px;}
	}
}