.button {

  @extend .font-stack-medium;

  background: #222;
  border: 1px solid transparent;
  border-radius: 5px;
  display: inline-block;
  color: $white;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 16px 20px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, color 0.2s, border-color 0.2s;
	-moz-appearance: none;
	-webkit-appearance: none;

  &.compact {
    padding: 15px 25px;
  }

  &.green {

    background: $accent;

    &:hover {
      background: $accent_dark;
      color: $white;
    }

  }

  &.transparent {

    background: transparent;
    color: $lightgray;
    border-color: $lightgray;

    &:hover {
      background: $lightgray;
      color: $white;
    }

  }
  .fa { margin-right: 5px; font-size: 1.3em; }

}